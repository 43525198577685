
import Main from "./pages/Main";
import Crypto from "./pages/Crypto";
import Fiat from "./pages/Fiat";
import Inner from "./pages/Inner";
import Token from "./pages/Token";
import React, {useState, useEffect, useRef,createContext} from 'react';
import {Route, Routes,BrowserRouter as Router,
} from 'react-router-dom';
import './assets/styles/main.css';
import './assets/styles/reset.css';
import { useSearchParams, useNavigate } from "react-router-dom";

export const UserContext = React.createContext(null);

function App() {
  const [user, setUser] = useState(null);
  const [p2pDetail, setP2pDetail] = useState(null);
  

  return (
    <UserContext.Provider  value={{
      user: user, setUser: setUser,
      p2pDetail:p2pDetail, setP2pDetail:setP2pDetail
    }}>
    <Router>
      <Routes>
            <Route path="/" element={<Main/>}/>
            <Route path="/crypto" element={<Crypto/>}/>
            <Route path="/fiat" element={<Fiat/>}/>
            <Route path="/inner" element={<Inner/>}/>
            <Route path="/token" element={<Token/>}/>
        </Routes>
      </Router>
      </UserContext.Provider>
  );
}

export default App;

import React, {useState,useEffect,useContext} from 'react';
import creditCard from '../assets/images/Credit-card.svg';
import longArrow from '../assets/images/long-arrow.svg';
import Arrow from '../assets/images/arrow.svg';
import Wallet from '../assets/images/Wallet.svg';
import {useSearchParams,useNavigate} from "react-router-dom";
import {UserContext}  from './../App';
import {useTranslation} from "react-i18next";
import i18next from 'i18next';

function Main() {
  const [cryptoW, setCryptoW] = useState('p2p');
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [backLoading, setBackLoading] = useState(false);
   const [searchParams] = useSearchParams();
   const token = searchParams.get('token');
   const uid = searchParams.get('uid')
   const pod_id = searchParams.get('pod_id')
   const lang = searchParams.get('lang','ru')

   useEffect(() => {
      i18next.changeLanguage(lang, (err, t) => {
            if (err) return console.log('something went wrong loading', err);
         });
      console.log('lang',lang,i18next.languages)
   }, []);

   useEffect(() => {
      const requestOptions = {
         method: 'GET',
         headers: { 'Content-Type': 'application/json','Authorization': `${token}` },
     };
      fetch('https://app.marketgame.space/api/profile/user-detail-web',requestOptions)
      .then((response) => response.json())
      .then(data => {
         setUser(data?.data);
      });

   }, []);
   
   function next() {
      if (cryptoW==='crypto') navigate(`/crypto?token=${token}&uid=${uid}&pod_id=${pod_id}`);
      else if (cryptoW==='p2p') navigate(`/fiat?token=${token}&uid=${uid}&pod_id=${pod_id}`);
      else if (cryptoW==='inner') navigate(`/inner?token=${token}&uid=${uid}&pod_id=${pod_id}`);
      else {
         alert('Временно не доступно')
      }
   }
   

   function toMarketPlace() {
      setBackLoading(true)
      setTimeout(
         function() {
            setBackLoading(false)
         }
         .bind(this),
         3000
     );
      window.location.href = 'https://t.me/partiagame_bot?startapp'
    }

    return (
      <div className="App" style={{paddingTop:'70px'}}>
      <div className="chose__screen" >
      <p className="screen__tetle">  {i18next.t('main.title')}
      </p>
      <div 
        className={`screen__withdraw-profit ${cryptoW==='p2p' ? "withdraw-profit_active" : ""}`}
        onClick={() => setCryptoW('p2p')}

        >
        {/* <img src={Wallet} className="App-logo" alt="logo" /> */}
         <p className="mt-2 screen__withdraw-profit-name">{i18next.t('main.fiat.title')} </p>
         <p className="screen__withdraw-profit-text">{i18next.t('main.fiat.desc')}</p>
         {cryptoW==='p2p' ? (
             <div className="arrow-right">
             <img src={Arrow} className="App-logo" alt="logo" />
          </div>
         ) : (null)}
      </div>
      
      <div 
        className={`screen__withdraw-profit ${cryptoW==='crypto' ? "withdraw-profit_active" : ""}`}
        onClick={() => setCryptoW('crypto')}
        >
        {/* <img src={creditCard} className="App-logo" alt="logo" /> */}
         <p className="mt-2 screen__withdraw-profit-name">{i18next.t('main.crypto.title')}</p>
         <p className="screen__withdraw-profit-text">{i18next.t('main.crypto.desc')}</p>

         {cryptoW==='crypto' ? (
             <div className="arrow-right">
             <img src={Arrow} className="App-logo" alt="logo" />
          </div>
         ) : (null)}
        
      </div>
      
     
      <div 
        className={`screen__withdraw-profit ${cryptoW==='inner' ? "withdraw-profit_active" : ""}`}
        onClick={() => setCryptoW('inner')}
        >
         <p className="mt-2 screen__withdraw-profit-name">{i18next.t('main.profit.title')}</p>
         <p className="screen__withdraw-profit-text">{i18next.t('main.profit.desc')}</p>
         {cryptoW==='inner' ? (
             <div className="arrow-right">
             <img src={Arrow} className="App-logo" alt="logo" />
          </div>
         ) : (null)}
      </div>
      <button className="btn-gradient mt-2"   onClick={() => next()}>
         {i18next.t('main.next')}&#8195;
        <img src={longArrow} className="App-logo" alt="logo" />
      </button>

      <button className="btn-black mt-1" onClick={() => toMarketPlace()} >
         {
            backLoading===true ? i18next.t('main.back.loading'): i18next.t('main.back.text')
         } 
      </button> 

      
   </div>
    </div>
    );
}

export default Main;

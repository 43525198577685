import React, {useState,useContext} from 'react';

import {useSearchParams,useNavigate} from "react-router-dom";
import {UserContext}  from '../App';
import i18next from 'i18next';
 

function Crypto({setNetwork}) {
  const [cryptoW, setCryptoW] = useState(false);
  const navigate = useNavigate();
  const [backLoading, setBackLoading] = useState(false);
  const [withdrawValue, setWithdrawValue] = useState('');
   const [searchParams] = useSearchParams()
   const token = searchParams.get('token')
  const { user, setUser } = useContext(UserContext);
  const networks = [
    {'name':'Binance Smart Chain','slug':'binance-smart-chain', 'img':'./images/arrow.svg'},
    {'name':'Tron TRC20','slug':'trc20', 'img':'./images/arrow.svg'},
    {'name':'ETH','slug':'ethereum', 'img':'./images/arrow.svg'},
  ]



    return (
        <div style={{position:'absolute',zIndex:'999',width:'100%', background:'rgba(255,255,255,0.5)', height:'100%'}}>
            <div className="chose__connection" style={{marginTop:'70px'}}>
            <p className="screen__tetle">{i18next.t('modal_network.title')}</p>
            {
                networks.map((netwok) => {
                    return (
                        <div className="block__chose_item border-gradient-40"  onClick={() => setNetwork(netwok)} >
                            <div>{netwok.name}</div>
                        </div>
                    )
                })
            }
           
            <button onClick={() => setNetwork(networks[0])}  className="btn-black" style={{marginTop: '16px'}}>{i18next.t('modal_network.button')}</button>
        </div>
     </div>
    );
}

export default Crypto;

import { useState, useEffect } from 'react';

function Timer ({ seconds }) {
  const [timeLeft, setTimeLeft] = useState(900 - seconds);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
    }, 1000);

    if (timeLeft === 0) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const minutes = Math.floor(timeLeft / 60);
  const secondsLeft = timeLeft % 60;
  const formattedTime = `${minutes.toString().padStart(2, '0')}:${secondsLeft.toString().padStart(2, '0')}`;
  if (timeLeft<0) return '00:00'
  else return formattedTime;
}

export default Timer;
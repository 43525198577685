import React, {useState,useContext} from 'react';
import i18next from 'i18next';
import creditCard from '../assets/images/Credit-card.svg';
import Net from '../assets/images/net.svg';

import {useSearchParams,useNavigate} from "react-router-dom";
 

function ModalInfo({toMarketPlace, close}) {

    return (
        <div style={{position:'absolute', zIndex:'999',width:'100%', height:'100%', background:'rgba(255,255,255,0.9)'}}>
            <div class="info__window"  style={{marginTop:'40px'}}>

                <div>
                <img style={{marginTop:'34px'}}   src={creditCard} alt=""/>
                    <p class="info__window-title">{i18next.t('crypto_success.information')}</p>
                </div>
                
    
                <p class="info__window-text mt-1"> {i18next.t('crypto_success.text_information')} </p>
                <button  onClick={() => toMarketPlace()}  class="btn-gradient" style={{fontSize:'14px'}}  >{i18next.t('crypto_success.back')}</button>
                <button  onClick={() => close(false)}  class="btn-black mt-1" style={{fontSize:'14px'}}  >{i18next.t('crypto_success.close')}</button>
            </div>
        </div>
    );
}

export default ModalInfo;
import React, {useState,useContext} from 'react';
import i18next from 'i18next';


function Crypto({banks, setBank}) {

    return (
        <div style={{position:'absolute',zIndex:'999',width:'100%', background:'rgba(255,255,255,0.5)', height:'100%'}}>
            <div className="chose__connection" style={{marginTop:'0px'}}>
            <p className="screen__tetle">{i18next.t('modal_bank.title')}</p>
            {
                banks.map((bank) => {
                    return (
                        <div className="block__chose_item border-gradient-40"  onClick={() => setBank(bank)} >
                            <div>{bank.name}</div>
                            <img style={{height: 15}}   src={bank.logo} alt=""/>
                        </div>
                    )
                })
            }
           
            <button onClick={() => setBank(banks[0])}  className="btn-black" style={{marginTop: '16px'}}>Вернуться</button>
        </div>
     </div>
    );
}

export default Crypto;

import React, {useState} from 'react';
import Tinkof from '../assets/images/logo-tinkoff.svg';
import longArrow from '../assets/images/long-arrow.svg';
import USDt from '../assets/images/tether-usdt-logo.svg';
import Arrow from '../assets/images/arrow.svg';
import Net from '../assets/images/net.svg';
import ModalNetwork from '../components/ModalNetwork';
import CryptoSuccess from '../components/CryptoSuccess';
import {useSearchParams,useNavigate} from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import i18next from 'i18next';


function Crypto() {
  const [cryptoW, setCryptoW] = useState(false);
  const navigate = useNavigate();
  const [showAddress, setShowAddress] = useState(false);

  const [backLoading, setBackLoading] = useState(false);
  const [ValueSetted, setValueSetted] = useState(false);
  const [NetworkSetted, setNetworkSetted] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [WalletDict, setWallet] = useState({});
  const [Ammount, setAmmount] = useState('');
  const [errorModal, setErrorModal] = useState(false);
  const [responseText, setresponseText] = useState('');

  const [Network , setNetworkd] = useState({'name':'Binance Smart Chain','slug':'binance-smart-chain' });
   const [searchParams] = useSearchParams()
   const token = searchParams.get('token')
   const uid = searchParams.get('uid')
   const pod_id = searchParams.get('pod_id')


   const setValue = (value) => {
    setValueSetted(true)
    setAmmount(value)
  }
  const setNetwork = (value) => {
    setNetworkSetted(true)
    sendRequest(value)
  }

  async function sendRequest(value) {
    setShowAddress(false)
    const data = {
        "asset":"usdt",
        "pod_id":pod_id,
        "uid":uid,
        "network":value?.slug
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','Authorization': `${token}` },
        body: JSON.stringify(data)
    };

    fetch('https://app.marketgame.space/api/wallet/get-crypto-address',requestOptions)
      .then(response => {
         response.json().then(data => {
            console.log(data)
            setWallet(data.data)
            setShowAddress(true)
            setNetworkd(value)

         })
      })
      .catch((err) => {
        console.log(err.message);
    });

  }


   function back() {
        navigate(`/?token=${token}&uid=${uid}&pod_id=${pod_id}`);
    }

   function toMarketPlace() {
      setBackLoading(true)

      setTimeout(
         function() {
            setBackLoading(false)
         }
         .bind(this),
         3000
     );
      window.location.href = 'https://t.me/partiagame_bot?startapp'

    }

    return (

                <div className="App">
                    { !NetworkSetted ? (<ModalNetwork setNetwork={setNetwork} />): (null)}
                    { successModal ? (<CryptoSuccess toMarketPlace={toMarketPlace}  close={setSuccessModal} />): (null)}
                       <div  style={{marginTop:'70px'}} className="chose__screen"  >
                        <p className="screen__tetle">{i18next.t('crypto.title')}
                        </p>
                        <div className="block__chose">
                            <div className="headphone__model border-gradient-40-2" style={{marginBottom:15}}>
                                <div className="headphone__model-img" style={{ background: 'linear-gradient(180deg, #FDF7E9 0%, #CFC6AB 103.7%)' }}>
                                    <img src={WalletDict?.item?.image} style={{maxWidth:40,maxHeight:40}} alt="" />
                                </div>
                                <div className="headphone__model-text">
                                    <p className="headphone__model-text-name">{WalletDict?.item?.name}</p>
                                    <p className="headphone__model-text-title">{i18next.t('crypto.newpod')}</p>
                                </div>
                                <div className="headphone__model-price">
                                    {WalletDict?.item?.price} $
                                </div>
                            </div>
                            <p>{i18next.t('crypto.network')}</p>
                            <div style={{display:'flex', gap:'10px'}}>
                                <div style={{flex: '0 0 65%'}}>
                                    <div className="block__chose_input"  onClick={() =>setNetworkSetted(false)}  >
                                        <div className="block__chose_input-img">
                                        <img   src={Net}  alt=""/>
                                        </div>
                                        <div className="delimiter"></div>
                                        <div className="block__chose_input-btn">
                                        <div className="block__chose_input-name">{Network.name}</div>
                                            <img style={{transform: 'rotate(270deg)', marginLeft:10,marginBottom:5}} src={Arrow} alt=""/>
                                        </div>
                                    </div>
                                </div>

                                <div className="block__chose_input" style={{flex: '0 0 35%'}}>
                                    <div className="block__chose_input-btn">
                                    <img style={{ maxWidth:20, marginRight:5}} src={USDt} alt=""/>
                                    <div style={{ marginLeft:8}}  className="block__chose_input-name">USDT</div>
                                    </div>
                                </div>
                            </div>
                            <p>{i18next.t('crypto.address')}</p>
                            {
                                showAddress? (
                                    <div style={{display:'flex', gap:'10px'}}>
                                    <div>
                                        <CopyToClipboard text={WalletDict?.address}
                                            onCopy={() => { alert('copied')}}
                                            >
                                        <div className="block__chose_input" style={{height:120}}>
                                            <div className="block__chose_input-img">
                                            <img src={Net}  alt=""/>
                                            </div>
                                            <div className="delimiter"></div>
                                            <div className="block__chose_input-btn">
                                            <div className="block__chose_input-name" style={{fontSize:14, wordBreak:'break-all'}}><strong>{WalletDict?.address}</strong></div>
                                            </div>
                                        </div>
                                        </CopyToClipboard>


                                     </div>
                                        <div className="" style={{width:'100%',justifyContent:'center',textAlign:'center'}}>
                                            <img style={{alignSelf:'center'}} src={`https://app.marketgame.space${WalletDict?.qr_address}`} alt=""/>
                                        </div>
                                    </div>
                                ):(
                                    null
                                )
                            }


                        </div>
                        {/* <div className="block__chose_money">
                            <p className="block__chose_money-text">Комиссия</p>
                            <p className="block__chose_money-money">300 &#8381;</p>
                        </div>
                        <div className="block__chose_money"  style={{marginTop: '5px'}} >
                            <p className="block__chose_money-text">Вы получите</p>
                            <p className="block__chose_money-money">700 $</p>
                        </div> */}
                        <button  onClick={() => setSuccessModal(true)}   className="btn-gradient" style={{marginBottom: '10px',marginTop: '20px'}}  >
                        {i18next.t('crypto.button')} &#8195;
                            <img src={longArrow} alt=""/>
                        </button>
                        <button  onClick={() => toMarketPlace()}  className="btn-black"  style={{marginBottom: '26px'}} >{i18next.t('crypto.back')}</button>
                    </div>
                </div>
    );
}

export default Crypto;

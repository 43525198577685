import React, {useState,useContext} from 'react';
import Headphones from '../assets/images/headphones.svg';
import successImg from '../assets/images/img-ok-window.svg';
import errorImg from '../assets/images/img-error-window.svg';
import i18next from 'i18next';


function ModalInfo({toMarketPlace, ammount,type,text}) {
    async function support () {
        window.open('tg://resolve?domain=Uran_support')
      }
    

    return (
        <div style={{position:'absolute', zIndex:'999',width:'100%', height:'100%', background:'rgba(255,255,255,0.8)'}}>
            <div class="info__window"  style={{marginTop:'70px'}}>
                
                {
                    type === 'success' ? (
                        <div>
                             <img style={{marginTop:'34px'}}   src={successImg} alt=""/>
                            <p class="info__window-title">{i18next.t('modal_info.success_pay')}</p>
                        </div>
                       
                    ) : (
                        <div>
                             <img style={{marginTop:'34px'}}   src={errorImg} alt=""/>
                        <p class="info__window-title">{i18next.t('modal_info.error_pay')}</p>
                        </div>
                        
                    )
                }
                {
                    ammount ? (
                        <div className="big-text">
                            <span>{ammount} ₽</span>
                        </div>
                    ):(
                        null
                    )
                }
               
                <p class="info__window-text mt-1">{text} </p>
{/*                 
                {
                    type === 'error' ? 
                    ( 
                    <button onClick={() => support()} className="btn-black" style={{ marginTop: '26px', marginBottom: '10px' }}>
                        <img style={{ marginRight: '16px' }} src={Headphones} alt="" />
                        {i18next.t('modal_info.support')}
                    </button>
                 ) : (null)
                } */}
               
                <button  onClick={() => toMarketPlace()}  class="btn-black" style={{fontSize:'14px'}}  >{i18next.t('modal_info.button')}</button>
            </div>
        </div>
    );
}

export default ModalInfo;
import React, { useEffect,useContext, useRef, useState } from 'react';
import Tinkof from '../assets/images/logo-tinkoff.svg';
import longArrow from '../assets/images/long-arrow.svg';
import Headphones from '../assets/images/headphones.svg';
import Net from '../assets/images/net.svg';
import Arrow from '../assets/images/arrow.svg';
import FlagRu from '../assets/images/flag-rus.svg';
import ModalBank from '../components/ModalBank';
import ModalCountry from '../components/ModalCountry';

import { useSearchParams, useNavigate } from "react-router-dom";
import ModalInfo from '../components/ModalInfo';
import Timer from '../components/Timer';
import {UserContext}  from '../App';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import i18next from 'i18next';

function Fiat () {
    const [cryptoW, setCryptoW] = useState(false);
    const navigate = useNavigate();
    const [backLoading, setBackLoading] = useState(false);
    const [loadingMerchant, setLoadingMerchant] = useState(false);
    const [timer, SetTimer] = useState(0);
    const [searchParams] = useSearchParams()
    const token = searchParams.get('token')
    const uid = searchParams.get('uid')
    const pod_id = searchParams.get('pod_id')
    const [errorModal, setErrorModal] = useState(false);
    const { p2pDetail, setP2pDetail } = useContext(UserContext);

    const [responseText, setresponseText] = useState('');
    const [bankSetted, setBankSetted] = useState(false);
    const [confirmed, SetConfirmed] = useState(false);


    const [isModalBankOpen, setModalBankOpen] = useState(false);
    const [isModalCountryOpen, setModalBankCountry] = useState(false);

    
    const [bankValue, setBankValue] = useState();
    const [banks, setBanks] = useState([]);
    
    const [countries, setCountrys] = useState([]);
    const [countryValue, setCountryValue] = useState({});
    

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
        };
        fetch(`https://app.marketgame.space/api/p2p/payment-detail?uid=${uid}`,requestOptions)
            .then((response) => response.json())
            .then(data => {
                setP2pDetail(data?.data);
        });
        setTimeout(() => { SetTimer(timer+8)  }, 8000);
    }, [uid, timer]);



    useEffect(() => {
        fetch(`https://app.marketgame.space/api/p2p/payment-methods`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `${token}` }
        })
        .then(response => {
            response.json().then((data) => {
            setresponseText(data.message);
            if (response.status === 200) {
              setCountrys (data.countries);
                setCountryValue(data.data[0].country);
                setBanks(data.data);
                setBankValue(data.data[0]);
                
            } else {
                setErrorModal(true);
            }
            })
        })
        .catch(error => {
            console.log(error)
        })
    }, []);




  async function support () {
    window.open('tg://resolve?domain=Uran_support')
  }

    async function signMerchant () {
    const data = bankValue
    data['type'] = 'card'
    data["uid_payment"] = uid

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': `${token}` },
      body: JSON.stringify(data)
    };
    console.log(requestOptions)
    setLoadingMerchant(true)
    fetch('https://app.marketgame.space/api/p2p/sign-merchant', requestOptions)
      .then(response => {
        response.json().then(data => {
          setresponseText(data.message);
          if (response.status === 200) {
            setP2pDetail(data?.data);
          }
          else {
            setErrorModal(true)
          }
          setLoadingMerchant(false)

        })
      })
      .catch((err) => {
        console.log(err.message);
        setLoadingMerchant(false)
      });

  }

  function back() {
    navigate(`/?token=${token}&uid=${uid}&pod_id=${pod_id}`);
    }

  const setBank = (value) => {
    setBankSetted(true)
    setBankValue(value)
  }

  function toMarketPlace () {
    setBackLoading(true)

    setTimeout(
      function () {
        setBackLoading(false)
      }
      ,
      3000
    );
    window.location.href = 'https://t.me/partiagame_bot?startapp'
  }


  function onConfirm () {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `${token}` },
      };

      return fetch(`https://app.marketgame.space/api/p2p/set-paid?uid=${uid}`, requestOptions)
        .then(response => {
          response.json().then(data => {
            setresponseText(data.message);
            if (response.status === 200) {
                setP2pDetail(data.data);
            }
            else {
              setErrorModal(true)
            }

          })
        })
        .catch((err) => {
          console.log(err.message);
        });
    }

  return (
    <div className="App" >

      {isModalBankOpen ? (<ModalBank banks={banks} setBank={(bank) => {
        setBank(bank);
        setModalBankOpen(false);
      }} />) : (null)}
      
      {isModalCountryOpen ? (<ModalCountry countries={countries} setCountry={(country) => {
        setCountryValue(country);
        setBanks(country.banks);
        setBankValue(country.banks[0]);
        setModalBankCountry(false);
      }} />) : (null)}



      {(p2pDetail?.status === 'approved' ) || (p2pDetail?.status === 'checking' && p2pDetail?.time_window_sec>300) ? (<ModalInfo toMarketPlace={toMarketPlace} ammount={p2pDetail.value} text={i18next.t("fiat.text_success")} type={'success'} />) : (null)}
      {p2pDetail?.status === 'rejected'  ? (<ModalInfo toMarketPlace={toMarketPlace} ammount={p2pDetail.value} text={i18next.t("fiat.text_error")} type={'error'} />) : (null)}
      {p2pDetail?.status === 'draft' ? <div style={{ marginTop: '60px' }} className="chose__screen">
        <p className="screen__tetle" style={{marginTop:'70px'}} > P2P EXPRESS
         </p>
        <div className="block__chose">
          <p>{i18next.t("modal_bank.country")}</p>
          <div className="block__chose_input"  onClick={() => setModalBankCountry(true)} >
            <div className="block__chose_input-img">
              <img style={{width:30}} src={countryValue ? countryValue.flag :  null } alt="" />
            </div>
            <div className="delimiter"></div>
            <div className="block__chose_input-btn">
              <div className="block__chose_input-name">{countryValue ? countryValue.name : '-'}</div>
              {/* <img style={{transform: 'rotate(270deg)'}} src={Arrow} alt=""/> */}
            </div>
          </div>
          {bankValue ? (
            <>
              <p>{i18next.t("modal_bank.title")}</p>
              <div className="block__chose_input" onClick={() => setModalBankOpen(true)} >
                <div className="block__chose_input-img">
                  <img src={bankValue.logo}  style={{maxHeight:30,maxWidth:45}} alt="" />
                </div>
                <div className="delimiter"></div>
                <div className="block__chose_input-btn">
                  <div className="block__chose_input-name">{bankValue.name}</div>
                  <img style={{ transform: 'rotate(270deg)' }} src={Arrow} alt="" />
                </div>
              </div></>
          ) : null}




        </div>
        {/* <p className="screen__text" style={{margin: '4px 0 40px'}} >
            В момент перехода на оплату будет открыто платежное окно банка. Проверка оплаты может занять до 5 минут.
         </p> */}

         {
          loadingMerchant ? (
            <button className="btn-gradient"   style={{ marginBottom: '10px', opacity:0.5 }} >
              {i18next.t("modal_bank.loading")}
          </button>
          
          ) : (
          <button className="btn-gradient" onClick={() => signMerchant()} style={{ marginBottom: '10px' }} >
              {i18next.t("modal_bank.pay")}
          </button>
          )
         }
        


        <button className="btn-black" onClick={() => toMarketPlace()} style={{ marginBottom: '26px' }} > {i18next.t("modal_bank.button")}</button>
      </div> : null}
      {p2pDetail && p2pDetail.status === 'process' ?
        <div style={{ marginTop: '80px', paddingBottom:'330px' }} className="chose__screen">
          <div className="headphone__model border-gradient-40-2">
            <div className="headphone__model-img" style={{ background: 'linear-gradient(180deg, #FDF7E9 0%, #CFC6AB 103.7%)' }}>
              <img src={p2pDetail?.item?.image} style={{maxWidth:40,maxHeight:40}} alt="" />
            </div>
            <div className="headphone__model-text">
              <p className="headphone__model-text-name">{p2pDetail?.item?.name}</p>
              <p className="headphone__model-text-title">{i18next.t("fiat.buy.new")}</p>
            </div>
            <div className="headphone__model-price">
            {p2pDetail.bank.currency=='usd' ? p2pDetail.usd_value.toLocaleString('ru') : p2pDetail.value.toLocaleString('ru')}  
            <span> {p2pDetail.bank.currency}</span>
            </div>
          </div>
          <div className="transfer">


            <div className="transfer__bank">
              <div className="transfer__bank-name">{i18next.t("fiat.transfer.title")}</div>
              <div className="transfer__bank-time">{<Timer seconds={p2pDetail.time_window_sec} />}</div>
            </div>

            <p className="transfer-sum">{i18next.t("fiat.transfer.transfer")}<span>
              {p2pDetail.bank.currency=='usd' ? p2pDetail.usd_value.toLocaleString('ru') : p2pDetail.value.toLocaleString('ru')} 
              <strong> {p2pDetail.bank.currency}</strong></span></p>

            <CopyToClipboard
                text={p2pDetail?.merchant_account?.address}
                onCopy={() => { alert('скопировано')}}
                >
                <p className="transfer-telephone" style={{alignItems:'center'}}>{i18next.t("fiat.transfer.on_phone")}<span>{p2pDetail.merchant_account.address}</span>
                    <span style={{padding:'5px 15px 3px 15px', backgroundColor:'#F6F6F6',borderRadius:10}}><img src={Net} style={{maxHeight:20,maxWidth:15}} alt=""  /></span>
                </p>
             </CopyToClipboard>


            <p className="transfer-telephone">{i18next.t("fiat.input_name")}<span>{p2pDetail.merchant_account.fio}</span></p>

            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                <div style={{}}>
                    <p className="transfer-bank-recipient">{i18next.t("fiat.transfer.to_bank")}
                            <span> {p2pDetail.bank.name}</span>
                    </p>
                </div>

                <div  style={{padding:'5px 20px 5px 20px', backgroundColor:'#F6F6F6',borderRadius:10}}>
                    <img src={p2pDetail.bank.logo} style={{maxHeight:25,maxWidth:45}} alt="" />
                </div>
            </div>

          </div>

          <div className="block__instructions mt-1">
            <p><span>1.</span> {i18next.t("fiat.transfer.instruction.first")}</p>
            <p><span>2.</span> {i18next.t("fiat.transfer.instruction.second")}</p>
            <p><span>3.</span> {i18next.t("fiat.transfer.instruction.third", {value:p2pDetail.value.toLocaleString('ru'),bank:p2pDetail.bank.name})}</p>
            <p><span>3.</span> {i18next.t("fiat.transfer.instruction.fourth")}</p>
          </div>
          <div className="checkbox__transfer">
            <input onChange={e => SetConfirmed(!confirmed)}  value={confirmed} type="checkbox" id="transfer_in_one_amount" name="transfer_in_one_amount" />
            <label htmlFor="transfer_in_one_amount">
            
            {i18next.t("fiat.transfer.user_pay")} </label>
          </div>
          {confirmed ? (
            <button onClick={onConfirm} className="btn-gradient" style={{ marginBottom: '10px' }}>
                {i18next.t("fiat.transfer.confirm")} 
                <img src={longArrow} style={{marginLeft:10}} alt="" />
            </button>
          ) : (
            <button  className="btn-gradient"  style={{ marginBottom: '10px',opacity:0.3 }}>
                 {i18next.t("fiat.transfer.confirm")} 
            </button>
          )
          }

          <button onClick={() => toMarketPlace()} className="btn-black" style={{ marginBottom: '26px' }}>{i18next.t("fiat.transfer.back")} </button>
        </div>
        : null}

      {p2pDetail && (p2pDetail.status === 'paid' || (p2pDetail?.status === 'checking' && p2pDetail?.time_window_sec<300)) ?
        <div style={{ marginTop: '80px' }} className="chose__screen">
          <div className="big-text">
          {i18next.t("fiat.merchant.text")} <span>{p2pDetail?.value} {p2pDetail?.bank?.currency}</span>
          </div>
          <p className="chose__screen-text" style={{ marginTop: '15px' }}>{i18next.t("fiat.merchant.text_second")}</p>
          <div style={{
            background: '#F6F6F6',
            borderRadius: '20px',
            width: '235px',
            height: '70px',
            margin: '55px auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '30px',
            fontWeight: '700',
          }}>
            <Timer seconds={p2pDetail?.time_window_sec}  />
          </div>
          <p className="chose__screen-text">{i18next.t("fiat.merchant.time")}</p>
          {/* <button onClick={() => support()} className="btn-black" style={{ marginTop: '26px', marginBottom: '10px' }}>
            <img style={{ marginRight: '16px' }} src={Headphones} alt="" />
            {i18next.t("fiat.merchant.support")}
          </button> */}
          <button onClick={() => toMarketPlace()} className="btn-black" style={{ marginBottom: '26px' }}> {i18next.t("fiat.merchant.back")}</button>
        </div> : null}
    </div>
  );
}

export default Fiat;

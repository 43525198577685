import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/reset.css';
import './assets/styles/main.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";


import common_sp from "./translations/sp/common.json";
import common_en from "./translations/en/common.json";
import common_ru from "./translations/ru/common.json";




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'ru',                              // language to use
  resources: {
      en: {
          common: common_en,               // 'common' is our custom namespace
          translation: common_en
      },
      ru: {
          common: common_ru,
          translation: common_ru
      },
      sp: {
          common: common_sp,
          translation: common_sp
      },
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

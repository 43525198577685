import React, {useState,useContext,useEffect} from 'react';
import Wallet from '../assets/images/uran_wallet.svg';
import longArrow from '../assets/images/long-arrow.svg';
import ArrowGradient from '../assets/images/arrow-gradient.png';
import Arrow from '../assets/images/arrow.svg';
import Net from '../assets/images/net.svg';
import ModalNetwork from '../components/ModalNetwork';
import ModalInfo from '../components/ModalInfo';
import {useSearchParams,useNavigate} from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {UserContext}  from '../App';
import i18next from 'i18next';


function Inner() {
  const [cryptoW, setCryptoW] = useState(false);
  const navigate = useNavigate();
  const [showAddress, setShowAddress] = useState(false);
  const { user, setUser } = useContext(UserContext);

  const [backLoading, setBackLoading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [ValueSetted, setValueSetted] = useState(false);
  const [NetworkSetted, setNetworkSetted] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [WalletDict, setWallet] = useState({});
  const [pod, setPod] = useState({});
  const [errorModal, setErrorModal] = useState(false);
  const [responseText, setresponseText] = useState('');
  const [Network , setNetworkd] = useState({'name':'Binance Smart Chain','slug':'binance-smart-chain' });
   const [searchParams] = useSearchParams()
   const token = searchParams.get('token')
   const uid = searchParams.get('uid')
   const pod_id = searchParams.get('pod_id')


   useEffect(() => {
    var Tpod = user?.pods.filter(x => x.id.toString() === pod_id)[0];
    setPod( Tpod );
    }, [user]);

    useEffect(() => {
    if(!user){
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json','Authorization': `${token}` },
        };
         fetch('https://app.marketgame.space/api/profile/user-detail-web',requestOptions)
         .then((response) => response.json())
         .then(data => {
            setUser(data?.data);
         });
    }
    }, []);

  async function sendRequest() {
    if (pod?.price*0.86>user?.score?.available_usd) {
        alert('Недостаточно средств')
        return
    }
    setLoading(true)

    const data = {
        "value": pod?.price,
        "inner": true,
        "pod": pod_id,
        "comment": 'buy from profit',
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','Authorization': `${token}` },
        body: JSON.stringify(data)
    };
    console.log(requestOptions)

    fetch('https://app.marketgame.space/api/wallet/new-withdraw',requestOptions)
      .then(response => {
         response.json().then(data => {
            setresponseText(data.message)
            if (response.status === 200) {
                setSuccessModal(true)
            }
            else{
                setErrorModal(true)
            }
            setLoading(false)

         })
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false)
    });

  }


   function back() {
        navigate(`/?token=${token}&uid=${uid}&pod_id=${pod_id}`);
    }

   function toMarketPlace() {
      setBackLoading(true)

      setTimeout(
         function() {
            setBackLoading(false)
         }
         .bind(this),
         3000
     );
      window.location.href = 'https://t.me/partiagame_bot?startapp'
    }


    return (

                <div className="App">
                    { successModal ? (<ModalInfo toMarketPlace={toMarketPlace}  text={responseText}  close={setSuccessModal} type={'success'} />): (null)}
                    { errorModal ? (<ModalInfo toMarketPlace={toMarketPlace} text={responseText} type={'error'}/>): (null)}
                       <div  style={{marginTop:'70px'}} className="chose__screen"  >
                        <p className="screen__tetle">{i18next.t('fiat.buy.title')}
                        </p>
                        <div className="block__chose">
                            { pod_id ? (
                                <div className="headphone__model border-gradient-40-2" style={{marginBottom:15}}>
                                    <div className="headphone__model-img" style={{ background: 'linear-gradient(180deg, #FDF7E9 0%, #CFC6AB 103.7%)' }}>
                                        <img src={pod?.image} style={{maxWidth:40,maxHeight:40}} alt="" />
                                    </div>
                                    <div className="headphone__model-text">
                                        <p className="headphone__model-text-name">{pod?.name}</p>
                                        <p className="headphone__model-text-title" style={{color:'#ADADAD'}}>{i18next.t('fiat.buy.new')}</p>
                                    </div>
                                    <div className="headphone__model-price">
                                        {pod?.price} $
                                    </div>
                                </div>
                                ) : (
                                    null
                                ) }


                            <div className="headphone__model " style={{marginBottom:15}}>
                                <div className="headphone__model-img" style={{ background: 'linear-gradient(180deg, #fff 0%, #fff 103.7%)' }}>
                                    <img src={Wallet} style={{maxWidth:40,maxHeight:40}} alt="" />
                                </div>
                                <div className="headphone__model-text">
                                    <p className="headphone__model-text-name" style={{marginTop:5}}>{i18next.t('inner.yourbalance')}</p>
                                </div>
                                <div className="headphone__model-price">
                                    {user?.score?.available_usd} $
                                </div>
                            </div>
                            <div className="block__instructions mt-1 transfer">

                              <p className="transfer-telephone" style={{alignItems:'center'}}>
                                <span style={{padding:'5px 15px 3px 0px', backgroundColor:'#F6F6F6',borderRadius:10}}>
                                        <img src={ArrowGradient} style={{maxHeight:30,maxWidth:18}} alt=""  />
                                    </span>
                                    {i18next.t('inner.title')} <span></span>
                               </p>

                                <p> {i18next.t('inner.desc')}<span> {Math.round(pod?.price*100)/100} $</span></p>
                                <p>{i18next.t('inner.when')}  <span> 1 {i18next.t('inner.minute')}  </span></p>
                                <p> {i18next.t('inner.commision')}  0%</p>


                            </div>

                        </div>
                        {
                            ((pod?.price*1<=user?.score?.available_usd) && (pod)) ? (

                                    Loading ? (
                                        <button  className="btn-gradient" style={{marginBottom: '10px',marginTop: '20px',opacity:0.4}}  >
                                            {i18next.t('inner.letpay')} ....
                                        </button>
                                    ) : (
                                        <button  onClick={() => sendRequest()}   className="btn-gradient" style={{marginBottom: '10px',marginTop: '20px'}}  >
                                            {i18next.t('inner.pay')} &#8195;
                                            <img src={longArrow} alt=""/>
                                        </button>
                                    )

                            ):(
                                <button  className="btn-gradient" style={{marginBottom: '10px',marginTop: '20px',opacity:0.4}}  >
                                {i18next.t('inner.not_enought')}
                            </button>
                            )
                        }


                        <button  onClick={() => toMarketPlace()}  className="btn-black"  style={{marginBottom: '26px'}} >{i18next.t('inner.back')}</button>
                    </div>
                </div>
    );
}

export default Inner;
